.avatar-uploader{
  width: auto;
}

.page-container{
  width: 65%;
  min-width: 900px;
  margin: auto;
}

.profile-header{
  padding-left: 0;
}

.change-email-btn{
  margin-left: 50px;
}

.profile-video{
  width: 60%;
}

.more-icon{
  color: black;
  font-size: x-large;
}

.upload-video-button{
  width: 100%;
  height: 100%;
  min-width: 900px;
  align-items: center;
  justify-content: center;
  display:flex;
  margin-top: 40px;
  
}
.button{
    align-items: center;
}